import React from "react"
import Layout from "../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Contacto = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Contacto"
        titleTemplate="%s | Eduardo Ceballos"
        description="Contacto"
        canonical="https://eduardoceballos.com/discografia"
        openGraph={{
          url: `https://eduardoceballos.com/discografia`,
          title: `Contacto`,
          description: `Contacto`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/index.png`,
              alt: `Contacto`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Contacto</h1>

      <h6>
        Cel: <a href="tel:+54 9 387 510-1026">+54 9 387 510-1026</a>
        <br />
        Email:{" "}
        <a href="mailto:eduardoceballos.salta@gmail.com">
          eduardoceballos.salta@gmail.com
        </a>
        <br />
        <br />
        <hr />
        <br />
        <a href="https://www.facebook.com/poeta.eduardo.ceballos">Facebook</a>
        <br />
        <a href="https://twitter.com/PoetadeSalta">Twitter</a>
        <br />
        <a href="https://www.instagram.com/poetaeduardoceballos">Instagram</a>
        <br />
      </h6>
    </Layout>
  )
}

export default Contacto
